import * as type from '../../actions/types';

const initialState = {
  onClickChatModal: false,
  commenterInfo: {}
};

const chatModal = (state = initialState, action) => {
  switch (action.type) {
    case (type.CHAT_OPEN_MODAL): {
      return {
        ...state,
        onClickChatModal: action.result
      }
    }
    case (type.CHAT_CLOSE_MODAL): {
      return {
        ...state,
        onClickChatModal: action.result
      }
    }
    case (type.CHAT_COMMENTER_INFO): {
      return {
        ...state,
        commenterInfo: action.commenterInfo
      }
    }
    default:
      return state
  }
};

export default chatModal;
