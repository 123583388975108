import * as type from '../../actions/types';

const initialState = {
  trainStationList : []
};

const trainStationList = (state = initialState, action) => {
  switch (action.type) {
    case (type.TRAINSTATIONLIST):
      return {
        ...state,
        trainStationList: action.trainStationListAll
      }
    default:
      return state
  } 
};

export default trainStationList;
