import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Posts from '../Posts/Posts';
import Loading from '../Loading/Loading';
import './MyPage.css';


const MyPage = () => {
  const userId = useSelector(state => state.user.user.id);

  const [postList, setPostList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let cleanUp = false;

    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/mypage`,
      method: 'GET',
      params: {
        userId,
      }
    })
      .then((res) => {
        if (!cleanUp) {
          setPostList(res.data.posts);
          setIsLoading(false);
        }
      })
      .catch(err => console.log(err));

    return () => {
      cleanUp = true;
    }

  }, []);

  return (
    <div className='mypageOuter'>
      <div className='mypageInner'>
        <h2>내 게시물</h2>
        {
          isLoading && <Loading />
        }
        {
          (!isLoading && !postList.length) &&
          <div>
            작성한 게시물이 없습니다.
          </div>
        }
        {!isLoading &&
          postList.map((el, i) => {
            return (
              <Posts
                key={i}
                id={el.id}
                title={el.title}
                writerId={el.writerId}
                writer={el.writer}
                pathName={el.pathName}
                depPlace={el.depPlace}
                arrPlace={el.arrPlace}
                arrTime={el.arrTime}
                depDate={el.depDate}
                depTime={el.depTime}
                decription={el.decription}
                progress={el.progress}
                personnel={el.personnel}
                comments={el.comments}
              />
            )
          })
        }
      </div>
    </div>
  );
};

export default withRouter(MyPage);
