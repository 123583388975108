import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { TiArrowRightOutline } from "react-icons/ti";
import {
  openDepModalAction,
  openArrModalAction,
  depDateAction
} from '../../actions/action';
import './NewPost.css';


const ModifyPost = (props) => {

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const depPlaceName = useSelector(state => state.trainInfo.depPlaceName);
  const arrPlaceName = useSelector(state => state.trainInfo.arrPlaceName);
  const modifyPostData = useSelector(state => state.modifyPostData.modifyPostData);
  const trainInfo = useSelector(state => state.trainInfo);


  const {
    _id,
    id,
    writerId,
    writer,
    writerImg,
    depTime,
    arrTime,
    depDate,
    decription,
    progress,
    personnel
  } = modifyPostData;


  const [modifyDecription, setModifyDecription] = useState(decription);
  const [modifyDepDate, setModifyDepDate] = useState(depDate);
  const [modifyDepTime, setModifyDepTime] = useState(depTime);
  const [modifyArrTime, setModifyArrTime] = useState(arrTime);
  const [modifyProgress, setModifyProgress] = useState(progress);
  const [modifyPersonnel, setModifyPersonnel] = useState(personnel);


  useEffect(() => {
    if (!isLoggedIn) {
      props.history.push('/login');
    }
  }, [isLoggedIn]);


  const handleModifyPostSubmit = (e) => {
    e.preventDefault();
    if (!modifyDepTime.length || !modifyArrTime.length || !modifyDecription.length) {
      alert('입력 칸을 모두 작성하세요');
    } else {
      dispatch(depDateAction(modifyDepDate));

      axios.put(`${process.env.REACT_APP_SERVER_URL}/posts/modify`, {
        modifyData: {
          _id,
          id,
          writerId,
          writer,
          writerImg,
          pathName: `/${depPlaceName}/${arrPlaceName}/${modifyDepDate}`,
          depPlace: depPlaceName,
          arrPlace: arrPlaceName,
          modifyDepDate,
          modifyDepTime,
          modifyArrTime,
          modifyDecription,
          modifyProgress,
          modifyPersonnel,
          modifyTrainInfo: trainInfo
        }
      })
        .then((res) => {
          if (res.data.result) {
            props.history.push(`/${depPlaceName}/${arrPlaceName}/${modifyDepDate}`)
          }
        })
        .catch(err => console.error(err));
    }
  };


  return (
    <div className='newPostOuterContainer'>
      <div className='newPostInnerContainer'>
        <div>
          <div className='mt-20 placeBox'>
            <div
              className='m-30 place'
              onClick={() => {
                dispatch(openDepModalAction());
              }}
            >
              {depPlaceName}
            </div>
            <div>
              <TiArrowRightOutline className='arrow'/>
            </div>
            <div
              className='m-30 place'
              onClick={() => {
                dispatch(openArrModalAction());
              }}
            >
              {arrPlaceName}
            </div>
          </div>
          <form>
            <div className='mt-20'>
              출발일
              <input
                type='date'
                className='newPostInput mt-20'
                value={modifyDepDate}
                onChange={(e) => {
                  setModifyDepDate(e.target.value);
                }}
              />
            </div>
            <div className='mt-20'>
              출발시간
              <input
                type='time'
                className='newPostInput mt-20'
                value={modifyDepTime}
                onChange={(e) => {
                  setModifyDepTime(e.target.value);
                }}
                required
              />
            </div>
            <div className='mt-20'>
              도착시간
              <input
                type='time'
                className='newPostInput mt-20'
                value={modifyDepTime}
                onChange={(e) => {
                  setModifyArrTime(e.target.value);
                }}
                required
              />
            </div>
            <div className='mt-20'>
              <textarea
                className='newPostTextarea'
                placeholder='내용 입력'
                value={modifyDecription}
                onChange={(e) => {
                  setModifyDecription(e.target.value);
                }}
                required
              >
              </textarea>
            </div>
            <div>
              <select
                className='mt-20'
                value={modifyProgress}
                onChange={(e) => {
                  setModifyProgress(e.target.value);
                }}
              >
                <option>진행 중</option>
                <option>진행 완료</option>
              </select>
            </div>
            <div>
              <select
                className='mt-20'
                value={modifyPersonnel}
                onChange={(e) => {
                  setModifyPersonnel(e.target.value);
                }}
              >
                <option>1명</option>
                <option>2명</option>
                <option>3명</option>
                <option>4명</option>
              </select>
            </div>
            <div className='newPostSubmitButtonOuter'>
              <button
                className='newPostSubmitButton'
                type='submit'
                onClick={handleModifyPostSubmit}
              >
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ModifyPost);
