import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { TiArrowRightOutline } from "react-icons/ti";
import './ChatRoom.css';


const ChatRoom = (props) => {
  const user = useSelector(state => state.user.user);
  const { chatData } = props;


  const [partner, setPartner] = useState('');
  const [partnerImg, setPartnerImg] = useState('');
  const [postData, setPostData] = useState('');


  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/posts/detail`,
      method: 'GET',
      params: {
        postId: chatData.postId
      }
    })
      .then((res) => {
        setPostData(res.data.result);
        if (chatData.partnerId !== user.id) {
          setPartner(chatData.partnerName);
          setPartnerImg(res.data.result.writerImg);
        } else if (chatData.senderId !== user.id) {
          setPartner(chatData.senderName);
          setPartnerImg(chatData.senderImg);
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }, []);

  const handleChatRoomOut = (e) => {
    e.stopPropagation();
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/chat/chatRoomOut`,
      method: 'DELETE',
      params: {
        chatId: chatData.chatId,
        userId: user.id
      }
    })
      .then((res) => {
        if (res.data.result === 'ok') {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }


  return (
    <div
      className='chatRoomOuter'
      onClick={(e) => {
        props.history.push(`/chat?room=${chatData.chatId}`)
      }}
    >
      <div className='partner'>
        <img className='partnerImg' src={partnerImg} />
        <p className='partnerName'>{partner}</p>
      </div>
      <div className='post'>
        <div className='inChatRoomPostDepdate'>
          {postData && postData.depDate}
        </div>
        <div className='inChatRoomPost'>
          {postData && (
            <>
              <div className='chatRoomPostItems'>
                <p>{postData.depPlace}</p>
                <p>{postData.depTime} 출발</p>
              </div>
              <div className='chatRoomPostItems'>
                <p><TiArrowRightOutline /></p>
              </div>
              <div className='chatRoomPostItems'>
                <p>{postData.arrPlace}</p>
                <p>{postData.arrTime} 도착</p>
              </div>
            </>
          )}
          <button
            className='chatRoomOut'
            onClick={handleChatRoomOut}
          >
            나가기
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ChatRoom);
