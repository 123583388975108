import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import {
  openChatModalAction,
  closeChatModalAction,
  commenterInfoAction
} from '../../actions/action';
import '../../components/Comment/Comment.css';



const SingleComment = (props) => {
  const dispatch = useDispatch();
  const storeUserId = useSelector(state => state.user.user.id);
  const chatModalSate = useSelector(state => state.chatModal.onClickChatModal);


  const {
    _id,
    postId,
    userId,
    userImg,
    userName,
    content,
    postTime
  } = props.comment;


  const [_postTime, setPostTime] = useState('');


  useEffect(() => {

    const post = new Date(Number(postTime))
    const year = post.getFullYear();
    const month = post.getMonth() + 1
    const date = post.getDate();
    const hours = post.getHours();
    const minutes = post.getMinutes();
    const seconds = post.getSeconds();

    setPostTime(`${year}-${month}-${date} ${hours}:${minutes}:${seconds}`);

  }, []);


  const handleRemoveComment = () => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/comments`,
      method: 'DELETE',
      params: {
        _id,
        postId
      }
    })
      .then(res => props.refreshFunction(res.data.result))
      .catch(err => console.error(err));
  };


  return (
    <div className='singleCommentsOuter'>
      <div>
        <img
          className='singleCommentImg'
          src={userImg}
          onClick={(e) => {
            if (storeUserId !== userId) {
              if (chatModalSate) {
                dispatch(closeChatModalAction());
              } else {
                dispatch(openChatModalAction());
                dispatch(commenterInfoAction({
                  postId,
                  userId,
                  userName
                }));
              }
            }
          }}
        />
      </div>
      <div className='userName'>{userName}</div>
      <div className='contentBox'>
        <div className='singleCommentContent'>{content}</div>
        <div className='postTime'>{_postTime}</div>
      </div>
      {
        storeUserId === userId &&
        <div>
          <button
            className='deleteComment'
            onClick={handleRemoveComment}
          >
            삭제
          </button>
        </div>
      }
    </div>
  );
};

SingleComment.propTypes = {
  comment: PropTypes.object
};

export default withRouter(SingleComment);
