import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  logoutAction,
  closeUserModalAction,
  closeDepModalAction,
  closeArrModalAction
} from '../../actions/action';
import { withRouter } from 'react-router-dom';
import { IoIosChatbubbles } from "react-icons/io";
import './UserModal.css';



const UserModal = (props) => {
  const dispatch = useDispatch();
  const depModal = useSelector(state => state.depModal.onModal);
  const arrModal = useSelector(state => state.arrModal.onModal);

  const closeSearchStationModal = () => {
    if (depModal) {
      dispatch(closeDepModalAction());
    } else if (arrModal) {
      dispatch(closeArrModalAction());
    }
  };

  return (
    <div className='userModalOuter'>
      <div>
        <button
          className='userModalButton myPostButton'
          onClick={(e) => {
            closeSearchStationModal();
            dispatch(closeUserModalAction());
            props.history.push('/my-page');
          }}
        >
          내 게시물
        </button>
      </div>
      <div>
        <button
          className='userModalButton'
          onClick={(e) => {
            closeSearchStationModal();
            dispatch(closeUserModalAction());
            props.history.push('/chatList');
          }}
        >
          <IoIosChatbubbles />
           채팅
          </button>
      </div>
      <div>
        <button
          className='userModalButton logoutButton'
          onClick={(e) => {
            closeSearchStationModal();
            localStorage.clear();
            dispatch(closeUserModalAction());
            dispatch(logoutAction());
          }}
        >
          로그아웃
          </button>
      </div>
    </div>
  );
};

export default withRouter(UserModal);
