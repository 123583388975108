import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import io from 'socket.io-client';
import InfoBar from '../InfoBar/InfoBar';
import Messages from '../Messages/Messages';
import axios from 'axios';
import './Chat.css';

let socket;

const Chat = (props) => {
  const { room } = queryString.parse(window.location.search);
  const roomSplit = room.split('n');

  const user = useSelector(state => state.user.user);

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [postData, setPostData] = useState({});


  useEffect(() => {
    socket = io(process.env.REACT_APP_SERVER_URL);

    socket.emit('join', {
      chatId: room
    });

    let cleanUp = false;

    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/posts/detail`,
      method: 'GET',
      params: {
        postId: roomSplit[0],
      }
    })
      .then((res) => {
        if (!cleanUp) {
          setPostData(res.data.result);
        }
      })
      .catch(err => console.error(err))

    return () => {
      cleanUp = true;

      socket.emit('disconnect');

      socket.off();
    };

  }, []);



  useEffect(() => {
    socket.on('message', (message) => {
      setMessages(message);
      setMessage('');
    });

  }, [messages]);

  const sendMessage = (e) => {
    e.preventDefault();

    if (message.length) {

      socket.emit('sendMessage', {
        chatId: room,
        postId: roomSplit[0],
        partnerId: roomSplit[1],
        partnerName: roomSplit[2],
        senderId: roomSplit[3],
        senderName: user.name,
        senderImg: user.image,
        message: message,
        timestamps: Date.now()
      }, () => {
        return setMessage('');
      });

    }

  };

  return (
    <div className='chatOuterCotainer'>
      <InfoBar postData={postData} />
      <div className='chatInnerContainer'>
        {
          messages.map((message, i) => {
            return (
              <Messages
                key={i}
                message={message}
              />
            )
          })
        }
      </div>
      <form className='chatForm'>
        <input
          className='chatInput'
          type='text'
          value={message}
          placeholder='메세지를 입력하세요...'
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          onKeyPress={(e) => {
            return e.key === 'Enter' ? sendMessage(e) : null
          }}
        />
        <button
          className='messageSendBtn'
          onClick={sendMessage}
        >
          send
          </button>
      </form>
    </div>
  );
};

export default Chat;
