import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import ChatRoom from '../ChatRoom/ChatRoom';
import Loading from '../Loading/Loading';
import { IoIosChatbubbles } from "react-icons/io";
import './ChatList.css';

const ChatList = (props) => {
  const user = useSelector(state => state.user.user);

  const [isLoading, setIsLoading] = useState(true);
  const [chatList, setChatList] = useState([]);


  useEffect(() => {

    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/chat/getChatList`,
      method: 'GET',
      params: {
        userId: user.id
      }
    })
      .then((res) => {
        let resData = res.data.result;

        let uniqueChatId = Array.from(new Set(resData.map((el) => {
          return el.chatId
        })));

        const uniqueChatList = [];

        for (let i = 0; i < uniqueChatId.length; i++) {
          const result = resData.find((el) => {
            return el.chatId === uniqueChatId[i]
          });
          uniqueChatList.push(result);
        }

        setChatList(uniqueChatList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

  }, []);

  return (
    <div className='chatListOuter'>
      <h2> <IoIosChatbubbles /> my 채팅</h2>
      {
        isLoading && <Loading />
      }
      {
        (!isLoading && !chatList.length) &&
        <div> 메세지가 없습니다.</div>
      }
      {
        !isLoading && chatList.map((el, i) => {
          return (
            <ChatRoom
              className='chatRoom'
              key={i}
              chatData={el}
            />
          )
        })
      }
    </div>
  );
};

export default withRouter(ChatList);
