import * as type from '../../actions/types';

const initialState = {
  modifyPostData: {}
};

const modifyPostData = (state = initialState, action) => {
  switch (action.type) {
    case (type.MODIFY_POST_DATA):
      return {
        ...state,
        modifyPostData: action.modifyPostData
      }
    default:
      return state
  };
};

export default modifyPostData;
