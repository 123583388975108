import React from 'react';
import { FadeLoader } from 'halogenium';
import './Loading.css';

const Loading = () => {
  return (
    <div className='loadingOuter'>
      <FadeLoader color="black" size="100px" margin="4px" />
    </div>
  );
};

export default Loading;
