import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  openDepModalAction,
  openArrModalAction,
  depDateAction
} from '../../actions/action';
import './Main.css';


const Main = (props) => {

  const dispatch = useDispatch();
  const depPlaceName = useSelector(state => state.trainInfo.depPlaceName);
  const arrPlaceName = useSelector(state => state.trainInfo.arrPlaceName);

  const [depPlace, setDepPlace] = useState('');
  const [arrPlace, setArrPlace] = useState('');
  const [departureDate, setDepartureDate] = useState('');


  useEffect(() => {
    const today = new Date();

    const year = today.getFullYear();

    let month = today.getMonth() + 1;
    month = month < 10 ? `0${month}` : `${month}`;

    let date = today.getDate();
    date = date < 10 ? `0${date}` : `${date}`;

    setDepPlace(depPlaceName);
    setArrPlace(arrPlaceName);
    setDepartureDate(`${year}-${month}-${date}`);

  }, []);


  const handleDepSearch = (e) => {
    dispatch(openDepModalAction());
  };

  const handleArrSearch = (e) => {
    dispatch(openArrModalAction());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(depDateAction(departureDate));
    props.history.push(`/${depPlace}/${arrPlace}/${departureDate}`);
  };

  return (
    <div className='mainOuterContainer'>
      <div className='mainInnerContainer'>
        <div>
          <form>
            <div className='itemBox'>
              <input
                className='mainPageInput'
                type='text'
                placeholder='출발역'
                value={depPlace}
                onChange={(e) => {
                  setDepPlace(e.currentTarget.value);
                }}
                onClick={handleDepSearch}
                required
              />
            </div>
            <div className='itemBox'>
              <input
                className='mainPageInput'
                type='text'
                placeholder='도착역'
                value={arrPlace}
                onChange={(e) => {
                  setArrPlace(e.currentTarget.value);
                }}
                onClick={handleArrSearch}
                required
              />
            </div>
            <div className='itemBox'>
              <input
                className='mainPageInput'
                type='date'
                value={departureDate}
                onChange={(e) => {
                  setDepartureDate(e.target.value);
                }}
                required
              />
            </div>
            <div className='itemBox'>
              <button
                className='submitButton searchButton'
                type='submit'
                onClick={handleSubmit}
              >
                조회하기
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Main);
