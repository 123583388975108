import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../../actions/action';
import KakaoLogin from 'react-kakao-login';
import { withRouter } from 'react-router-dom';
import { MdDirectionsRailway } from "react-icons/md";
import axios from 'axios';
import './Login.css';


const Login = (props) => {

  const dispath = useDispatch();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      props.history.goBack();
    }
  }, [isLoggedIn]);


  useEffect(() => {
    if (userData) {
      axios.post(`${process.env.REACT_APP_SERVER_URL}/user`, userData)
        .then((res) => {
          if (res.data.result === 'ok') {
            dispath(loginAction(userData));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [userData]);


  const handleKakaoLogin = (res) => {
    const profile = res.profile;

    setUserData({
      id: String(profile.id),
      name: profile.properties.nickname,
      email: profile.kakao_account.email,
      image: profile.kakao_account.profile.profile_image_url,
      provide: 'kakao'
    });

  };


  return (
    <div className='loginOuterContainer'>
      <div className='loginInnerContainer'>
        <h1 className='heading'>
          <MdDirectionsRailway />
          ktx - gogo
        </h1>
        <div className='loginButton'>
          <KakaoLogin
            jsKey={process.env.REACT_APP_KAKAO_JS_KEY}
            onSuccess={handleKakaoLogin}
            onFailure={(res) => console.log(res)}
            getProfile={true}
            useDefaultStyle
          />
        </div>
      </div>
    </div>
  );
};


export default withRouter(Login);
