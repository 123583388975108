import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  closeChatModalAction
} from '../../actions/action';
import axios from 'axios';
import './ChatModal.css';


const ChatModal = (props) => {
  const dispatch = useDispatch();
  const storeUserId = useSelector(state => state.user.user.id);
  const commenterInfo = useSelector(state => state.chatModal.commenterInfo);


  return (
    <div
      className='chatOuter'
      onClick={(e) => {
        dispatch(closeChatModalAction());
      }}
    >
      <div className='chatInner'>
        <div>
          {commenterInfo.userName} 님과
        </div>
        <button 
          className='chatButton'
          onClick={(e) => {
            if (storeUserId !== commenterInfo.userId) {
              axios({
                url: `${process.env.REACT_APP_SERVER_URL}/chat/getChat`,
                method: 'GET',
                params: {
                  postId: commenterInfo.postId,
                  userId: commenterInfo.userId,
                }
              })
                .then((res) => {
                  if (res.data.result) {
                    dispatch(closeChatModalAction());
                    props.history.push(`/chat?room=${res.data.result}`);
                  } else {
                    dispatch(closeChatModalAction());
                    props.history.push(`/chat?room=${commenterInfo.postId}n${commenterInfo.userId}n${commenterInfo.userName}n${storeUserId}`);
                  }
                })
                .catch(err => console.error(err))
            }
          }}
        >
          채팅하기
        </button>
      </div>
    </div>
  );
};

export default withRouter(ChatModal);
