import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '../Menu/Menu';
import { closeDepModalAction, closeArrModalAction, closeChatModalAction } from '../../actions/action';
import './Header.css';


const Header = () => {
  const dispatch = useDispatch();
  const depModal = useSelector(state => state.depModal.onModal);
  const arrModal = useSelector(state => state.arrModal.onModal);
  const chatModal = useSelector(state => state.chatModal.onClickChatModal);


  return (
    <div className='headerOuter'>
      <Link
        to={'/'}
        className='headerIcon'
        onClick={(e) => {
          if (depModal) {
            dispatch(closeDepModalAction());
          } else if (arrModal) {
            dispatch(closeArrModalAction());
          } else if (chatModal) {
            dispatch(closeChatModalAction());
          }
        }}
      >
        KTX gogo
      </Link>
      <span className='menuComponentOuter'>
        <Menu />
      </span>
    </div>
  );
};

export default Header;
