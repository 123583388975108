import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import Auth from './hoc/auth';
import Header from './containers/Header/Header';
import MyPage from './components/MyPage/MyPage';
import Subscribe from './components/Subscribe/Subscribe';
import Chat from './components/Chat/Chat';
import Login from './containers/Login/Login';
import Main from './containers/Main/Main';
import DepModal from './containers/Modal/DepModal';
import ArrModal from './containers/Modal/ArrModal';
import NewPost from './containers/NewPost/NewPost';
import DetailPost from './containers/DetailPost/DetailPost';
import PostPage from './containers/PostPage/PostPage';
import ModifyPost from './containers/NewPost/ModifyPost';
import ChatList from './components/ChatList/ChatList';


import { trainStationListAllAction } from './actions/action';

import dotenv from 'dotenv';
dotenv.config();

const App = () => {
  const dispatch = useDispatch();
  const depModal = useSelector(state => state.depModal.onModal);
  const arrModal = useSelector(state => state.arrModal.onModal);


  const [consonantList, setconsonantList] = useState([
    'ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'
  ]);
  const [mainStationList, setMainStationList] = useState([
    { nodeId: 'NAT010000', nodeName: '서울' }, { nodeId: 'NAT010032', nodeName: '용산' }, { nodeId: 'NAT010091', nodeName: '영등포' },
    { nodeId: 'NATH10219', nodeName: '광명' }, { nodeId: 'NAT010415', nodeName: '수원' }, { nodeId: 'NATH10960', nodeName: '천안아산' },
    { nodeId: 'NAT050044', nodeName: '오송' }, { nodeId: 'NAT011668', nodeName: '대전' }, { nodeId: 'NAT030057', nodeName: '서대전' },
    { nodeId: 'NATH12383', nodeName: '김천구미' }, { nodeId: 'NAT013271', nodeName: '동대구' }, { nodeId: 'NAT8B0351', nodeName: '포항' },
    { nodeId: 'NAT013841', nodeName: '밀양' }, { nodeName: 'NAT014281', nodeName: '구포' }, { nodeId: 'NAT014445', nodeName: '부산' },
    { nodeName: 'NATH13421', nodeName: '신경주' }, { nodeId: 'NATH13717', nodeName: '울산(통도사)' }, { nodeId: 'NAT880345', nodeName: '마산' },
    { nodeId: 'NAT880281', nodeName: '창원중앙' }, { nodeId: 'test경산', nodeName: '경산' }, { nodeId: 'NAT030508', nodeName: '논산' },
    { nodeId: 'NAT030879', nodeName: '익산' }, { nodeId: 'NAT031314', nodeName: '정읍' }, { nodeId: 'NAT031857', nodeName: '광주송정' },
    { nodeId: 'NAT032563', nodeName: '목포' }, { nodeId: 'NAT040257', nodeName: '전주' }, { nodeId: 'NAT041595', nodeName: '순천' }, { nodeId: 'NAT041993', nodeName: '여수EXPO' },
    { nodeId: 'NAT130126', nodeName: '청량리' }, { nodeId: 'NAT601936', nodeName: '강릉' }, { nodeId: 'NAT110147', nodeName: '행신' }, { nodeId: 'test정동진', nodeName: '정동진' }
  ]);

  useEffect(() => {
    let cleanUp = false;

    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/trainInfo/getTrainStationListAll`,
      method: 'GET',
    }).then((res) => {
      if (!cleanUp) {
        dispatch(trainStationListAllAction(res.data.result));
      }
    }).catch((err) => {
      console.error(err);
    });


    return () => {
      cleanUp = true;
    };

  }, []);

  return (
    <div data-test="appComponent">
      {depModal && <DepModal consonantList={consonantList} mainStationList={mainStationList} />}
      {arrModal && <ArrModal consonantList={consonantList} mainStationList={mainStationList} />}
      <Router>
        <Header />
        <Switch>
          <Route path='/' exact component={Auth(Main, false)} />
          <Route path='/login' exact component={Auth(Login, false)} />
          <Route path='/my-page' exact component={Auth(MyPage, true)} />
          <Route path='/my-page/subscribe' component={Auth(Subscribe, true)} />
          <Route path='/newPost' component={Auth(NewPost, true)} />
          <Route path='/modifyPost' component={Auth(ModifyPost, true)} />
          <Route path='/:depPlace/:arrPlace/:date' exact component={Auth(PostPage, false)} />
          <Route path='/:depPlace/:arrPlace/:date/:id' component={Auth(DetailPost, false)} />
          <Route path='/chat' component={Auth(Chat, true)} />
          <Route path='/chatList' component={Auth(ChatList, true)} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
