import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Posts from '../Posts/Posts';
import Loading from '../Loading/Loading';
import { FaHeart } from "react-icons/fa";
import './Subscribe.css';


const Subscribe = () => {
  const userId = useSelector(state => state.user.user.id);

  const [subscribeList, setSubscribeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let cleanUp = false;

    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/mypage/subscribe`,
      method: 'GET',
      params: {
        userId,
      }
    })
      .then((res) => {
        if (!cleanUp) {
          setSubscribeList(res.data.subscribePost);
          setIsLoading(false);
        }
      })
      .catch(err => console.log(err));

    return () => {
      cleanUp = true;
    }

  }, []);

  return (
    <div className='subscribeOuter'>
      <div className='subscibeInner'>
        <h2>
          <FaHeart /> 관심 게시물
        </h2>
        {
          isLoading && <Loading />
        }
        {!isLoading &&
          subscribeList.map((el, i) => {
            return (
              <Posts
                key={i}
                id={el.id}
                title={el.title}
                writerId={el.writerId}
                writer={el.writer}
                pathName={el.pathName}
                depPlace={el.depPlace}
                arrPlace={el.arrPlace}
                depDate={el.depDate}
                depTime={el.depTime}
                arrTime={el.arrTime}
                decription={el.decription}
                progress={el.progress}
                personnel={el.personnel}
                comments={el.comments}
              />
            )
          })
        }
        {
          (!isLoading && !subscribeList.length) &&
          <div className='noInterestPosts'>
            관심 게시물이 없습니다.
          </div>
        }
      </div>
    </div>
  );
};

export default withRouter(Subscribe);
