export const LOGIN_USER = 'LOGIN_USER';

export const LOGOUT_USER = 'LOGOUT_USER';

export const DEP_OPEN_MODAL = 'DEP_OPEN_MODAL';

export const DEP_CLOSE_MODAL = 'DEP_CLOSE_MODAL';

export const ARR_OPEN_MODAL = 'ARR_OPEN_MODAL';

export const ARR_CLOSE_MODAL = 'ARR_CLOSE_MODAL';

export const DEP_PLACE = 'DEP_PLACE';

export const ARR_PLACE = 'ARR_PLACE';

export const DEP_DATE = 'DEP_DATE';

export const MODIFY_POST_DATA = 'MODIFY_POST_DATA';

export const USER_OPEN_MODAL = 'USER_OPEN_MODAL';

export const USER_CLOSE_MODAL = 'USER_CLOSE_MODAL';

export const TRAINSTATIONLIST = 'TRAINSTATIONLIST';

export const CHAT_OPEN_MODAL = 'CHAT_OPEN_MODAL';

export const CHAT_CLOSE_MODAL = 'CHAT_CLOSE_MODAL';

export const CHAT_COMMENTER_INFO = 'CHAT_COMMENTER_INFO';
