import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { TiArrowRightOutline } from "react-icons/ti";
import {
  openDepModalAction,
  openArrModalAction,
  depDateAction
} from '../../actions/action';
import './NewPost.css';


const NewPost = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const userId = useSelector(state => state.user.user.id);
  const userName = useSelector(state => state.user.user.name);
  const user = useSelector(state => state.user.user);
  const trainInfo = useSelector(state => state.trainInfo);
  const depPlaceName = useSelector(state => state.trainInfo.depPlaceName);
  const arrPlaceName = useSelector(state => state.trainInfo.arrPlaceName);
  const departureDate = useSelector(state => state.trainInfo.depDate);


  const [decription, setDecription] = useState('');
  const [depDate, setDepDate] = useState(departureDate);
  const [depTime, setDepTime] = useState('');
  const [arrTime, setArrTime] = useState('');
  const [progress, setProgress] = useState('진행 중');
  const [personnel, setPersonnel] = useState('1명');

  useEffect(() => {
    if (!isLoggedIn) {
      props.history.push('/login');
    }
  }, [isLoggedIn]);

  const handleNewPostSubmit = (e) => {
    e.preventDefault();
    if (!depTime.length || !arrTime.length || !decription.length) {
      alert('입력 칸을 모두 작성하세요');
    } else {
      dispatch(depDateAction(depDate));

      const time = Date.now();

      axios.post(`${process.env.REACT_APP_SERVER_URL}/posts`, {
        id: String(time),
        writerId: userId,
        writer: userName,
        writerImg: user.image,
        pathName: `/${depPlaceName}/${arrPlaceName}/${depDate}`,
        depPlace: depPlaceName,
        arrPlace: arrPlaceName,
        depTime,
        arrTime,
        depDate,
        decription,
        progress,
        personnel,
        trainInfo
      })
        .then((res) => {
          if (res.data.result === 'ok') {
            props.history.push(
              `/${depPlaceName}/${arrPlaceName}/${depDate}`
            );
          }
        })
        .catch(err => console.error(err));
    }
  };


  return (
    <div className='newPostOuterContainer'>
      <div className='newPostInnerContainer'>
        <div>
          <div className='mt-20 placeBox'>
            <div
              className='m-30 place'
              onClick={() => {
                dispatch(openDepModalAction());
              }}
            >
              {depPlaceName}
            </div>
            <div>
              <TiArrowRightOutline className='arrow'/>
            </div>
            <div
              className='m-30 place'
              onClick={() => {
                dispatch(openArrModalAction());
              }}
            >
              {arrPlaceName}
            </div>
          </div>
          <form>
            <div className='mt-20'>
              출발일
              <input
                type='date'
                className='newPostInput mt-20'
                value={depDate}
                onChange={(e) => {
                  setDepDate(e.target.value);
                }}
              />
            </div>
            <div className='mt-20'>
              출발시간
              <input
                type='time'
                className='newPostInput mt-20'
                onChange={(e) => {
                  setDepTime(e.target.value);
                }}
                required
              />
            </div>
            <div className='mt-20'>
              도착시간
              <input
                type='time'
                className='newPostInput mt-20'
                onChange={(e) => {
                  setArrTime(e.target.value);
                }}
                required
              />
            </div>
            <div className='mt-20'>
              <textarea
                className='newPostTextarea'
                placeholder='내용 입력'
                onChange={(e) => {
                  setDecription(e.target.value);
                }}
                required
              >
              </textarea>
            </div>
            <div>
              <select
                className='mt-20'
                onChange={(e) => {
                  setProgress(e.target.value);
                }}
              >
                <option>진행 중</option>
                <option>진행 완료</option>
              </select>
            </div>
            <div>
              <select
                className='mt-20'
                value={personnel}
                onChange={(e) => {
                  setPersonnel(e.target.value);
                }}
              >
                <option>1명</option>
                <option>2명</option>
                <option>3명</option>
                <option>4명</option>
              </select>
            </div>
            <div className='newPostSubmitButtonOuter'>
              <button
                className='newPostSubmitButton'
                type='submit'
                onClick={handleNewPostSubmit}
              >
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewPost);
