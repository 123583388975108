import * as type from './types';
import dotenv from 'dotenv';
dotenv.config();

export const loginAction = (userData) => {
  return {
    type: type.LOGIN_USER,
    userData,
    result: true
  };
};

export const logoutAction = () => {
  return {
    type: type.LOGOUT_USER,
    userData: {},
    result: false
  };
};

export const openDepModalAction = () => {
  return {
    type: type.DEP_OPEN_MODAL,
    result: true
  };
};

export const closeDepModalAction = () => {
  return {
    type: type.DEP_CLOSE_MODAL,
    result: false
  };
};

export const openArrModalAction = () => {
  return {
    type: type.ARR_OPEN_MODAL,
    result: true
  };
};

export const closeArrModalAction = () => {
  return {
    type: type.ARR_CLOSE_MODAL,
    result: false
  };
};

export const depPlaceInfoAction = (depPlaceId, depPlaceName) => {
  return {
    type: type.DEP_PLACE,
    depPlaceId,
    depPlaceName
  };
};

export const arrPlaceInfoAction = (arrPlaceId, arrPlaceName) => {
  return {
    type: type.ARR_PLACE,
    arrPlaceId,
    arrPlaceName
  };
};

export const depDateAction = (depDate) => {
  return {
    type: type.DEP_DATE,
    depDate
  };
};

export const modifyPostDataAction = (modifyPostData) => {
  return {
    type: type.MODIFY_POST_DATA,
    modifyPostData
  };
};

export const openUserModalAction = () => {
  return {
    type: type.USER_OPEN_MODAL,
    result: true
  };
};

export const closeUserModalAction = () => {
  return {
    type: type.USER_CLOSE_MODAL,
    result: false
  };
};

export const trainStationListAllAction = (trainStationListAll) => {
  return {
    type: type.TRAINSTATIONLIST,
    trainStationListAll
  };
};

export const openChatModalAction = () => {
  return {
    type: type.CHAT_OPEN_MODAL,
    result: true
  };
};

export const closeChatModalAction = () => {
  return {
    type: type.CHAT_CLOSE_MODAL,
    result: false
  };
};

export const commenterInfoAction = (commenterInfo) => {
  return {
    type: type.CHAT_COMMENTER_INFO,
    commenterInfo
  };
};
