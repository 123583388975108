import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TiArrowRightOutline } from "react-icons/ti";
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import './Posts.css';

const Posts = (props) => {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const userId = useSelector(state => state.user.user.id);

  const {
    id,
    writer,
    pathName,
    depPlace,
    arrPlace,
    depDate,
    depTime,
    arrTime,
    progress,
    personnel,
  } = props;

  const [subscribe, setSubscribe] = useState(false);
  const [subscribeCount, setSubscribeCount] = useState('');

  useEffect(() => {
    let cleanUp = false;

    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/subscribe`,
      method: 'GET',
      params: {
        postId: id,
        userId
      }
    })
      .then((res) => {
        if (!cleanUp) {
          setSubscribe(res.data.result);
          setSubscribeCount(res.data.subscribeCount);
        }
      })
      .catch(err => console.log(err));

    return () => {
      cleanUp = true;
    };

  }, []);


  const handleSubscribe = () => {
    if (isLoggedIn) {
      if (subscribe) {
        axios.delete(`${process.env.REACT_APP_SERVER_URL}/subscribe`, {
          params: {
            postId: id,
            userId
          }
        })
          .then((res) => {
            setSubscribe(res.data.result);
            setSubscribeCount(res.data.subscribeCount);
          })
          .catch(err => console.log(err));

      } else {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/subscribe`, {
          postId: id,
          userId
        })
          .then((res) => {
            setSubscribe(res.data.result);
            setSubscribeCount(res.data.subscribeCount);
          })
          .catch(err => console.log(err));
      }
    } else {
      props.history.push('/login');
    }
  };


  return (
    <div
      className='postOuter'
      onClick={() => {
        props.history.push(`${pathName}/${id}`);
      }}>
      <div className='postContent postItem'>
        {depDate}
      </div>
      <div className='postContent postItem'>
        <div>
          {depPlace}
        </div>
        <div>
          {depTime} 출발
        </div>
      </div>
      <div className='postContent postItem'>
        <TiArrowRightOutline />
      </div>
      <div className='postContent postItem'>
        <div>
          {arrPlace}
        </div>
        <div>
          {arrTime} 도착
        </div>
      </div>
      <div className='test postItem'>
        {personnel}/4명
      </div>
      <div className='test postItem'>
        {progress}
      </div>
      <div className='test postItem'>
        {writer}
      </div>
      <div
        className='postItem'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {subscribe ?
          <>
            <MdFavorite
              className='subscribe'
              onClick={handleSubscribe}
            />
            {subscribeCount}
          </> :
          <>
            <MdFavoriteBorder
              className='subscribe'
              onClick={handleSubscribe}
            />
            {subscribeCount}
          </>
        }
      </div>
    </div>
  );
};

Posts.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  writer: PropTypes.string,
  pathName: PropTypes.string,
  depPlace: PropTypes.string,
  arrPlace: PropTypes.string,
  depDate: PropTypes.string,
  depTime: PropTypes.string,
  arrTime: PropTypes.string,
  progress: PropTypes.string,
  personnel: PropTypes.string,
};

export default withRouter(Posts);
