import * as type from '../../actions/types';

const initialState = {
  onClickUserModal: false
};

const userModal = (state = initialState, action) => {
  switch (action.type) {
    case (type.USER_OPEN_MODAL): {
      return {
        ...state,
        onClickUserModal: action.result
      }
    }
    case (type.USER_CLOSE_MODAL): {
      return {
        ...state,
        onClickUserModal: action.result
      }
    }
    default:
      return state
  }
};

export default userModal;
