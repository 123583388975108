import React from 'react';
import { withRouter } from 'react-router-dom';
import { TiDeleteOutline } from "react-icons/ti";

import './InfoBar.css';

const InfoBar = (props) => {
  const { postData } = props;

  return (
    <div className='infoBarOuter'>
      <div className='leftInnerContainer'>
        <h3
          onClick={(e) => {
            if(postData) {
              const resultPathName = `${postData.pathName}/${postData.id}`;
              window.location.pathname = resultPathName;
            } else {
              alert('게시물이 삭제되었습니다.')
            }
          }}
        >
          게시물 보기
        </h3>
      </div>
      <div className='rightInnerContainer'>
        <TiDeleteOutline
          className='deleteOutline'
          onClick={(e) => {
            props.history.goBack();
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(InfoBar);
