import { combineReducers } from 'redux';
import user from '../reducers/user/user';
import depModal from '../reducers/depModal/depModal';
import arrModal from '../reducers/arrModal/arrModal';
import trainInfo from '../reducers/trainInfo/trainInfo';
import userModal from '../reducers/userModal/userModal';
import modifyPostData from '../reducers/modifyPostData/modifyPostData';
import trainStationList from '../reducers/trainStationList/trainStationList';
import chatModal from '../reducers/chatModal/chatModal';


export default combineReducers({
  user,
  depModal,
  arrModal,
  trainInfo,
  modifyPostData,
  userModal,
  trainStationList,
  chatModal
});
