import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


export default (SpecialComponent, option, adminRoute = null) => {

  const AuthenticateCheck = (props) => {
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);


    useEffect(() => {
      if (!isLoggedIn && option) {
        props.history.push('/login');
      }
    }, []);

    return (
      <SpecialComponent />
    )
  }

  return AuthenticateCheck;
};
