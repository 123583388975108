import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SingleComment from '../../containers/SingleComment/SingleComment';
import axios from 'axios';
import './Comment.css';


const Comment = (props) => {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const userId = useSelector(state => state.user.user.id);
  const userName = useSelector(state => state.user.user.name);
  const userImg = useSelector(state => state.user.user.image);

  const {
    refreshFunction,
    commentList,
    postId
  } = props;

  const [comment, setComment] = useState('');

  const handleSubmitComment = (e) => {
    e.preventDefault();
    if (isLoggedIn) {
      axios.post(`${process.env.REACT_APP_SERVER_URL}/comments`, {
        data: {
          postId,
          userId,
          userImg,
          userName,
          comment,
          postTime: String(Date.now())
        }
      })
        .then((res) => {
          console.log(res.data)
          refreshFunction(res.data.result);
          setComment('');
        })
        .catch(err => console.error(err));
    } else {
      props.history.push('/login')
    }
  };

  return (
    <div>
      {
        commentList.map((comment, i) => {
          return (
            <SingleComment
              key={i}
              comment={comment}
              refreshFunction={refreshFunction}
            />
          )
        })
      }
      <form
        className='commentForm'
        onSubmit={handleSubmitComment}
      >
        <textarea
          className='commentTestarea'
          type='text'
          placeholder='댓글'
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
        <button
          className='commentSubmitButton'
          type='submit'
        >
          등록
        </button>
      </form>
    </div>
  );
};

Comment.propTypes = {
  refreshFunction: PropTypes.func,
  commentList: PropTypes.array,
  postId: PropTypes.string
};

export default withRouter(Comment);
