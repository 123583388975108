import * as type from '../../actions/types';

const initialState = {
  onModal: false
};

const arrModal = (state = initialState, action) => {
  switch (action.type) {
    case (type.ARR_OPEN_MODAL): {
      return {
        ...state,
        onModal: action.result
      }
    }
    case (type.ARR_CLOSE_MODAL): {
      return {
        ...state,
        onModal: action.result
      }
    }
    default:
      return state
  }
};

export default arrModal;
