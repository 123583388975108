import React from 'react';
import { useSelector } from 'react-redux';
import ScrollToBottom from 'react-scroll-to-bottom';
import './Messages.css';

const Messages = ({ message }) => {

  const user = useSelector(state => state.user.user);

  let isCurrentUser = false;

  if (user.name === message.senderName) {
    isCurrentUser = true;
  }

  return (
    <div>
      <ScrollToBottom className="messages">
        {
          isCurrentUser ? (
            <div className='messageOuter justifyEnd'>
              <div className='messageInner backgroundBlue'>
                <p className='messageText colorWhite'>{message.message}</p>
              </div>
            </div>
          ) : (
              <div className='messageOuter justifyStart'>
                <img className='senderImg' src={message.senderImg}/>
                <p className='messageUserName'>{message.senderName}</p>
                <div className='messageInner backgroundLight'>
                  <p className='messageText colorDark'>{message.message}</p>
                </div>
              </div>
            )
        }
      </ScrollToBottom>
    </div>
  );
};

export default Messages;
