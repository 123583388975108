import * as type from '../../actions/types';

const initialState = {
  depPlaceId: null,
  depPlaceName: '',
  depDate: '',
  arrPlaceId: null,
  arrPlaceName: ''
};

const trainInfo = (state = initialState, action) => {
  switch (action.type) {
    case (type.DEP_PLACE):
      return {
        ...state,
        depPlaceId: action.depPlaceId,
        depPlaceName: action.depPlaceName
      }
    case (type.ARR_PLACE):
      return {
        ...state,
        arrPlaceId: action.arrPlaceId,
        arrPlaceName: action.arrPlaceName
      }
    case (type.DEP_DATE):
      return {
        ...state,
        depDate: action.depDate
      }
    default:
      return state
  }
};

export default trainInfo;
