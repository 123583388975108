import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeArrModalAction,
  arrPlaceInfoAction
} from '../../actions/action';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as ChosungSearch from 'hangul-chosung-search-js';
import './Modal.css';


const ArrModal = (props) => {
  const dispatch = useDispatch();
  const trainListAll = useSelector(state => state.trainStationList.trainStationList);

  const { consonantList, mainStationList } = props;

  const [trainStationList, setTrainStationList] = useState([]);
  const [trainStationListAll, setTrainStationListAll] = useState(trainListAll);
  const [arrSearchTrainList, setArrSearchTrainList] = useState([]);


  const handleTrainStation = (e) => {
    const consonant = e.target.value;
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/trainInfo/getTrainStationList`,
      method: 'GET',
      params: {
        data: consonant
      }
    }).then((res) => {
      setTrainStationList(res.data.result);
    }).catch((err) => {
      console.error(err);
    });
  };


  const handleStationInfo = (e) => {
    const arrPlaceId = e.target.value;
    const arrPlaceName = e.target.innerText;
    dispatch(arrPlaceInfoAction(arrPlaceId, arrPlaceName));
    dispatch(closeArrModalAction());
  };


  return (
    <div
      className='modalOuterContainer'
      onClick={(e) => {
        dispatch(closeArrModalAction());
      }}
    >
      <div className='modalInnerContainer'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='searchOuter'>
          <input
            className='searchStationInput'
            type='search'
            placeholder='역 검색'
            onChange={(e) => {
              const trainStationList = trainStationListAll.map((el) => {
                return el.nodeName;
              });
              setArrSearchTrainList(ChosungSearch.searchList(e.currentTarget.value, trainStationList));
              const arrPlace = trainStationListAll.find((el) => {
                return el.nodeName === e.currentTarget.value
              });
              if (arrPlace) {
                dispatch(arrPlaceInfoAction(arrPlace.nodeId, arrPlace.nodeName));
              }
            }}
          />
          <ul>
            {
              arrSearchTrainList.map((el, i) => {
                return (
                  <li
                    key={i}
                    onClick={(e) => {
                      const arrPlace = trainStationListAll.find((el) => {
                        return el.nodeName === e.currentTarget.innerHTML
                      });
                      if (arrPlace) {
                        dispatch(arrPlaceInfoAction(arrPlace.nodeId, arrPlace.nodeName));
                        setArrSearchTrainList([]);
                        dispatch(closeArrModalAction());
                      }
                    }}
                  >
                    {el}
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className='buttonOuter'>
          <div>주요역</div>
          {mainStationList.map((el, i) => {
            return (
              <button 
                className='stationButton'
                key={i}
                value={el.nodeId}
                onClick={handleStationInfo}
              >
                {el.nodeName}
              </button>
            )
          })}
        </div>
        <div className='buttonOuter'>
          <div>
            철도역
          </div>
          {consonantList.map((consonant, i) => {
            return (
              <button
                className='cityButton'
                key={i}
                value={consonant}
                onClick={handleTrainStation}
              >
                {consonant}
              </button>
            )
          })}
        </div>
        <div className='buttonOuter'>
          {trainStationList.map((el, i) => {
            return (
              <button
                className='stationButton'
                key={i}
                value={el.nodeId}
                onClick={handleStationInfo}
              >
                {el.nodeName}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  );
};

ArrModal.propTypes = {
  cityList: PropTypes.array
};

export default ArrModal;
