import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";
import { TiArrowRightOutline } from "react-icons/ti";
import {
  modifyPostDataAction,
  depPlaceInfoAction,
  arrPlaceInfoAction,
  depDateAction
} from '../../actions/action';
import Loading from '../../components/Loading/Loading';
import Comment from '../../components/Comment/Comment';
import ChatModal from '../ChatModal/ChatModal';
import axios from 'axios';
import './DetailPost.css';


const DetailPost = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const userId = useSelector(state => state.user.user.id);
  const chatModalSate = useSelector(state => state.chatModal.onClickChatModal);


  const pathname = props.location.pathname;
  const result = pathname.split('/');
  const postId = result[result.length - 1];

  const [detailPost, setDetailPost] = useState({});
  const [commentList, setCommentList] = useState([]);
  const [subscribe, setSubscribe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    let cleanUp = false;

    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/posts/detail`,
      method: 'GET',
      params: {
        postId,
        userId
      }
    })
      .then((res) => {
        if (res.data.result && !cleanUp) {
          setDetailPost(res.data.result);
        }
        if (res.data.subscribe && !cleanUp) {
          setSubscribe(true);
          setIsLoading(false);
        }
      })
      .catch(err => console.log(err));


    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/comments`,
      method: 'GET',
      params: {
        data: postId
      }
    })
      .then((res) => {
        if (!cleanUp) {
          setCommentList(res.data.result);
          setIsLoading(false);
        }
      })
      .catch(err => console.log(err));

    return () => {
      cleanUp = true;
    }

  }, []);

  const refreshFunction = (newCommentList) => {
    setCommentList(newCommentList);
  };


  const handleModifyPost = () => {
    dispatch(modifyPostDataAction(detailPost));
    props.history.push('/modifyPost');
  };


  const handleRemovePost = () => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/posts/detail`,
      method: 'DELETE',
      params: {
        postId
      }
    })
      .then((res) => {
        if (res.data.result === 'ok') {
          props.history.goBack();
        }
      })
      .catch(err => console.log(err));
  };


  const handleSubscribe = () => {
    if (subscribe) {
      axios.delete(`${process.env.REACT_APP_SERVER_URL}/subscribe`, {
        params: {
          postId,
          userId
        }
      })
        .then((res) => {
          setSubscribe(res.data.result)
        })
        .catch(err => console.log(err));

    } else {
      axios.post(`${process.env.REACT_APP_SERVER_URL}/subscribe`, {
        postId,
        userId
      })
        .then((res) => {
          console.log(res.data.result);
          setSubscribe(res.data.result);
        })
        .catch(err => console.log(err));
    }
  };



  return (
    <div className='detailPostOuterContainer'>
      {chatModalSate && <ChatModal />}
      <div className='detailPostInnerContainer'>
        {
          isLoading && <Loading />
        }
        {
          !isLoading && (
            <div>
              <div className='mt-20 detailPostButtonOuter'>
                <button
                  className='detailPostButton back'
                  onClick={(e) => {
                    props.history.goBack();
                  }}
                >
                  <FaArrowLeft />
                </button>
                {subscribe ? (
                  <button
                    className='detailPostButton subscribeBtn'
                    onClick={handleSubscribe}
                  >
                    <MdFavorite className='subscribe' />
                  </button>
                ) : (
                    <button
                      className='detailPostButton'
                      onClick={handleSubscribe}
                    >
                      <MdFavoriteBorder className='subscribe' />
                    </button>
                  )}
              </div>
              <div className='postInfoOuter mt-20'>
                <div className='mt-20 postInfoItem'>
                  {detailPost.depDate}
                </div>
                <div className='postInfoOuter mt-20 postInfoItem'>
                  <div>
                    <div>
                      {detailPost.depPlace}
                    </div>
                    <div>
                      {detailPost.depTime}
                    </div>
                  </div>
                  <div>
                    <TiArrowRightOutline />
                  </div>
                  <div>
                    <div>
                      {detailPost.arrPlace}
                    </div>
                    <div>
                      {detailPost.arrTime}
                    </div>
                  </div>
                </div>
                <div className='mt-20 postInfoItem'>
                  {detailPost.personnel}/4명
                </div>
                <div className='mt-20 postInfoItem'>
                  {detailPost.progress}
                </div>
                <div className='mt-20 postInfoItem'>
                  {detailPost.writer}
                </div>
              </div>
              <div className='mt-20 outline detailContent'>
                {detailPost.decription}
              </div>
              <div className='mt-20 detailPostButtonOuter'>
                <button
                  className='detailPostButton'
                  onClick={(e) => {
                    const depPlaceId = detailPost.trainInfo.depPlaceId;
                    const depPlaceName = detailPost.trainInfo.depPlaceName;
                    const arrPlaceId = detailPost.trainInfo.arrPlaceId;
                    const arrPlaceName = detailPost.trainInfo.arrPlaceName;
                    const depDate = detailPost.trainInfo.depDate;
                    dispatch(depPlaceInfoAction(depPlaceId, depPlaceName));
                    dispatch(arrPlaceInfoAction(arrPlaceId, arrPlaceName));
                    dispatch(depDateAction(depDate));
                    props.history.push(detailPost.pathName);
                  }}
                >
                  목록으로
                </button>
                {
                  detailPost.writerId === userId ?
                    (
                      <div >
                        <button
                          className='detailPostButton'
                          onClick={handleModifyPost}
                        >
                          수정
                        </button>
                        <button
                          className='detailPostButton'
                          onClick={handleRemovePost}
                        >
                          삭제
                        </button>
                      </div>
                    ) : (
                      <button
                        className='detailPostButton'
                        onClick={(e) => {
                          if (isLoggedIn) {
                            props.history.push(`/chat?room=${postId}n${detailPost.writerId}n${detailPost.writer}n${userId}`);
                          } else {
                            props.history.push('/login')
                          }
                        }}
                      >
                        채팅하기
                      </button>
                    )
                }
              </div>
              <div className='mt-20'>
                <Comment
                  refreshFunction={refreshFunction}
                  commentList={commentList}
                  postId={postId}
                />
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default withRouter(DetailPost);
