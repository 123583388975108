import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  openDepModalAction,
  openArrModalAction,
  depDateAction
} from '../../actions/action';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { TiArrowRightOutline } from "react-icons/ti";
import Loading from '../../components/Loading/Loading';
import Posts from '../../components/Posts/Posts';
import axios from 'axios';
import './PostPage.css';



const PostPage = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const departureDate = useSelector(state => state.trainInfo.depDate);
  const depPlaceName = useSelector(state => state.trainInfo.depPlaceName);
  const arrPlaceName = useSelector(state => state.trainInfo.arrPlaceName);

  const category = [
    '출발일',
    '출발역',
    '',
    '도착역',
    '인원',
    '진행여부',
    '작성자',
    '스크랩'
  ];

  const [posts, setPosts] = useState([]);
  const [filterPosts, setFilterPosts] = useState([]);
  const [depDate, setDepDate] = useState(departureDate);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState(false);


  useEffect(() => {
    const pathName = `/${depPlaceName}/${arrPlaceName}/${depDate}`;

    props.history.push(pathName);

    let cleanUp = false;

    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/posts`,
      method: 'GET',
      params: {
        data: pathName
      }
    })
      .then((res) => {
        const result = res.data.result;
        if (!cleanUp) {
          setPosts(result);
          setIsLoading(false);
        }
      })
      .catch(err => console.error(err));

    return () => {
      cleanUp = true;
    }

  }, [isLoading]);


  const handlePreviousDate = () => {
    const selectDate = new Date(depDate);
    let previousDate = selectDate.setDate(
      selectDate.getDate() - 1
    );
    previousDate = new Date(previousDate);

    const year = previousDate.getFullYear();

    const month = previousDate.getMonth() + 1 < 10 ?
      `0${previousDate.getMonth() + 1}` :
      previousDate.getMonth() + 1;

    const date = previousDate.getDate() < 10 ?
      `0${previousDate.getDate()}` :
      previousDate.getDate();

    setIsLoading(true);
    setDepDate(`${year}-${month}-${date}`);
    dispatch(
      depDateAction(`${year}-${month}-${date}`)
    );

    props.history.push(
      `/${depPlaceName}/${arrPlaceName}/${year}-${month}-${date}`
    );
  };


  const handleNextDate = () => {
    const selectDate = new Date(depDate);
    let nextDate = selectDate.setDate(
      selectDate.getDate() + 1
    );
    nextDate = new Date(nextDate);

    const year = nextDate.getFullYear();

    const month = nextDate.getMonth() + 1 < 10 ?
      `0${nextDate.getMonth() + 1}` :
      nextDate.getMonth() + 1;

    const date = nextDate.getDate() < 10 ?
      `0${nextDate.getDate()}` :
      nextDate.getDate();

    setIsLoading(true);
    setDepDate(`${year}-${month}-${date}`);
    dispatch(
      depDateAction(`${year}-${month}-${date}`)
    );

    props.history.push(
      `/${depPlaceName}/${arrPlaceName}/${year}-${month}-${date}`
    );
  };

  const handleFilterProgress = () => {
    setIsLoading(true);
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/posts/progress`,
      method: 'GET',
      params: {
        pathName: `/${depPlaceName}/${arrPlaceName}/${depDate}`,
        progress: '진행 중'
      }
    })
      .then((res) => {
        setIsLoading(false);
        setFilter(true);
        setFilterPosts(res.data.result);
      })
      .catch(err => console.error(err));
  };



  return (
    <div className='postPageOuterContainer'>
      <div className='postPageInnerContainer'>
        <div className='postBox'>
          <div className='placeBox'>
            <div
              className='place'
              onClick={(e) => {
                dispatch(openDepModalAction());
              }}
            >
              <div className='placeName depPlaceName'>{depPlaceName}</div>
            </div>
            <div>
              <TiArrowRightOutline className='arrow' />
            </div>
            <div
              className='place'
              onClick={() => {
                dispatch(openArrModalAction());
              }}
            >
              <div className='placeName arrPlaceName'>{arrPlaceName}</div>
            </div>
          </div>
          <div className='dateBox'>
            <button
              className='prevDate IoIoButton dateItem'
              onClick={handlePreviousDate}
            >
              <FaArrowAltCircleLeft
                className='arrowBtn'
              />
            </button>
            <span
              className='date dateItem'
            >
              {depDate}
            </span>
            <button
              className='IoIoButton dateItem'
              onClick={handleNextDate}
            >
              <FaArrowAltCircleRight
                className='arrowBtn'
              />
            </button>
          </div>
          <div className='categoryBox'>
            {category.map((category, i) => {
              return <div key={i} className='category'>{category}</div>
            })}
          </div>
          {
            isLoading && <Loading />
          }
          {
            (!isLoading && !posts.length) &&
            <div className='noPosts'>
              게시물이 없습니다.
            </div>
          }
          {(!isLoading && !filter) && posts.map((el, i) => {
            return (
              <Posts
                key={i}
                id={el.id}
                title={el.title}
                writerId={el.writerId}
                writer={el.writer}
                pathName={el.pathName}
                depPlace={el.depPlace}
                arrPlace={el.arrPlace}
                depDate={el.depDate}
                depTime={el.depTime}
                arrTime={el.arrTime}
                decription={el.decription}
                progress={el.progress}
                personnel={el.personnel}
                comments={el.comments}
              />
            )
          })}
          {(!isLoading && filter) && filterPosts.map((el, i) => {
            return (
              <Posts
                key={i}
                id={el.id}
                title={el.title}
                writerId={el.writerId}
                writer={el.writer}
                pathName={el.pathName}
                depPlace={el.depPlace}
                arrPlace={el.arrPlace}
                depDate={el.depDate}
                depTime={el.depTime}
                arrTime={el.arrTime}
                decription={el.decription}
                progress={el.progress}
                personnel={el.personnel}
                comments={el.comments}
              />
            )
          })}
        </div>
        <div>
          <button
            className='newPostButton newBtn'
            onClick={() => {
              if (isLoggedIn) {
                props.history.push('/newPost');
              } else {
                props.history.push('/login')
              }
            }}
          >
            글쓰기
          </button>
          <button
            className='newPostButton filterBtn'
            onClick={handleFilterProgress}
          >
            진행중 보기
          </button>
          <button
            className='newPostButton notFilterBtn'
            onClick={() => {
              setFilter(false);
            }}
          >
            전체보기
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PostPage);
