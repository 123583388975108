import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegHeart } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import UserModal from '../UserModal/UserModal';
import {
  openUserModalAction,
  closeUserModalAction,
  closeDepModalAction,
  closeArrModalAction,
  closeChatModalAction
} from '../../actions/action';
import './Menu.css';


const Menu = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const userImg = useSelector(state => state.user.user.image);
  const userModal = useSelector(state => state.userModal.onClickUserModal);
  const depModal = useSelector(state => state.depModal.onModal);
  const arrModal = useSelector(state => state.arrModal.onModal);
  const chatModal = useSelector(state => state.chatModal.onClickChatModal);


  const closeModal = () => {
    if (depModal) {
      dispatch(closeDepModalAction());
    } else if (arrModal) {
      dispatch(closeArrModalAction());
    } else if (chatModal) {
      dispatch(closeChatModalAction());
    }
  };

  return (
    <div data-test='menuComponet'>
      {userModal && <UserModal />}
      <div className='menuOuterContainer'>
        {
          isLoggedIn ?
            <>
              <Link
                to={'/my-page/subscribe'}
                onClick={closeModal}
              >
                <FaRegHeart className='item' />
              </Link>
              <img
                className='userImg item userImgClick'
                onClick={(e) => {
                  if (userModal) {
                    dispatch(closeUserModalAction());
                  } else {
                    dispatch(openUserModalAction());
                  }
                }}
                src={userImg}
                alt='mypage'
              />
            </> :
            <Link
              to={'/login'}
              onClick={closeModal}
            >
              <FiUser className='item' />
            </Link>
        }
      </div>
    </div>
  );
};

export default Menu;
